import { useContext, useEffect, useState } from "react";
import Button from "./Button";
import { ProposalContext } from "../context/Dashboard/ProposalContext";
import { UserContext } from "../context/User";
import AdditionalValues from "./AdditionalValues";

const CardAdditional = ({
  isActive,
  idProposal,
  close,
  closeAll,
  advanceDiscount,
  proposalInfo,
}) => {
  const { applyAdditional } = useContext(ProposalContext);
  const [values, setValues] = useState([]);

  const containerStyle = {
    height: "600px",
    width: "800px",

    padding: "20px",
    backgroundColor: "#fff", // cor de fundo suave
    borderRadius: "8px", // bordas arredondadas
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // sombra suave para profundidade
  };

  const sectionStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    paddingBottom: "15px",
    borderBottom: "1px solid #dee2e6", // linha de separação entre seções
    marginBottom: "15px",
  };

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "15px",
  };

  const tableHeaderStyle = {
    backgroundColor: "#f1f3f5",
    color: "#343a40",
    padding: "10px",
    borderBottom: "2px solid #dee2e6",
    textAlign: "left",
    fontSize: "12px",
  };

  const tableCellStyle = {
    padding: "10px",
    borderBottom: "1px solid #dee2e6",
    fontSize: "12px",
  };

  const textStyle = {
    color: "#495057", // cor de texto neutra
    fontSize: "14px",
    fontWeight: "normal",
  };

  const textStyleNegative = {
    color: "#fff", // cor de texto neutra
    fontSize: "14px",
    fontWeight: "normal",
    backgroundColor: "#f60e50",
    borderRadius: "4px",
    padding: "0 10px",
  };

  const titleStyle = {
    color: "#212529", // cor mais escura para títulos
    fontSize: "22px",
    fontWeight: 400,
    marginBottom: "30px",
    textAlign: "center",
  };

  const subtitleStyle = {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#343a40",
    marginTop: "20px",
    marginBottom: "10px",
  };

  const fields = [
    {
      label: "Carga Paletizada",
      value: proposalInfo?.palletizedLoadShoose,
      style: textStyle,
      condition: proposalInfo?.palletizedLoadShoose,
    },
    {
      label: "Carga Perigosa",
      value: proposalInfo?.hazardousCargoShoose,
      style: textStyle,
      condition: proposalInfo?.hazardousCargoShoose,
    },
    {
      label: "Carga Empilhável",
      value: proposalInfo?.stackedLoadShoose,
      style: textStyle,
      condition: proposalInfo?.stackedLoadShoose,
    },
    {
      label: "Despacho Aduaneiro",
      value: proposalInfo?.emissionClearanceShoose,
      style: textStyle,
      condition: proposalInfo?.emissionClearanceShoose,
    },
    {
      label: "Emissão de Certificado de Origem",
      value: proposalInfo?.issuanceCertifOriginShoose,
      style: textStyle,
      condition: proposalInfo?.issuanceCertifOriginShoose,
    },
    {
      label: "Taxa de Ajudante",
      value: proposalInfo?.helperFeeShoose,
      style: textStyle,
      condition: proposalInfo?.helperFeeShoose,
    },
    {
      label: "Quantidade de Ajudantes",
      value: proposalInfo?.quantityHelperFeeShoose,
      style: textStyle,
      condition: proposalInfo?.quantityHelperFeeShoose,
    },
    {
      label: "Forma de Pagamento",
      value: proposalInfo?.formOfPayment,
      style: textStyle,
      condition: proposalInfo?.formOfPayment,
    },
    {
      label: "Tipo de Moeda",
      value: proposalInfo?.coinTypeInfo,
      style: textStyle,
      condition: proposalInfo?.coinTypeInfo,
    },
  ];

  const apply = async () => {
    try {
      const data = { additionals: values };
      await applyAdditional(idProposal, close, closeAll, data);
    } catch (error) {}
  };

  const distanceInKmVerify = (distance) => {
    console.log(distance);
    if (!isNaN(Number(distance)))
      if (Number(distance) > 200) return true;
      else return false;
    else return false;
  };

  const argentinaAndIncotermEXW = () => {
    if (proposalInfo?.argentinaEXW) return true;
    else return false;
  };

  const incotermArgentina = () => {
    if (argentinaAndIncotermEXW() && proposalInfo?.inconterm) return true;
    else return false;
  };

  return (
    <div style={containerStyle}>
      <h5 style={titleStyle}>Aplicar valores adicionais</h5>
      <div
        style={{
          overflow: "hidden",
          overflowY: "auto",
          height: "440px",
          paddingRight: "10px",
        }}
      >
        <h5 style={titleStyle}>Detalhes da Proposta</h5>

        <div style={sectionStyle}>
          <div style={textStyle}>
            <strong>Proposta Nº:</strong> {proposalInfo.number_proposal}
          </div>
          <div style={textStyle}>
            <strong>Data:</strong> {proposalInfo.date_now}
          </div>
          <div style={textStyle}>
            <strong>Validade:</strong> {proposalInfo.term}
          </div>
          <div style={textStyle}>
            <strong>Cliente:</strong> {proposalInfo.company}
          </div>
          <div style={textStyle}>
            <strong>Contato:</strong> {proposalInfo.name}
          </div>
          <div style={textStyle}>
            <strong>E-mail:</strong> {proposalInfo.email}
          </div>
          <div style={textStyle}>
            <strong>Telefone:</strong> {proposalInfo.whatsapp}
          </div>

          <div
            style={argentinaAndIncotermEXW() ? textStyleNegative : textStyle}
          >
            <strong>Origem:</strong> {proposalInfo.routeOrigin}
          </div>
          <div style={textStyle}>
            <strong>Destino:</strong> {proposalInfo.routeDest}
          </div>

          {proposalInfo?.inconterm ? (
            <div style={incotermArgentina() ? textStyleNegative : textStyle}>
              <strong>inconterm:</strong> {proposalInfo.inconterm}
            </div>
          ) : (
            <></>
          )}

          <div style={textStyle}>
            <strong>Tipo de Carga:</strong> {proposalInfo.merchandise}
          </div>
          <div style={textStyle}>
            <strong>Valor da Mercadoria:</strong> {proposalInfo.commodityPrice}
          </div>
          <div style={textStyle}>
            <strong>Peso:</strong> {proposalInfo.peso} kg
          </div>
          <div style={textStyle}>
            <strong>Cubagem Total:</strong> {proposalInfo.cubage}
          </div>
          <div style={textStyle}>
            <strong>Quantidade de Volumes:</strong> {proposalInfo.quantity}
          </div>
          <div style={textStyle}>
            <strong>Frete:</strong> {proposalInfo.freight}
          </div>
          <div style={textStyle}>
            <strong>Ad Valorem:</strong> {proposalInfo.adValorem}
          </div>
          {proposalInfo?.distanceInKmPickup ? (
            <div
              style={
                distanceInKmVerify(proposalInfo?.distanceInKmPickup)
                  ? textStyleNegative
                  : textStyle
              }
            >
              <strong>Distância de coleta:</strong>{" "}
              {proposalInfo?.distanceInKmPickup} Km
            </div>
          ) : (
            <></>
          )}
          {proposalInfo?.distanceInKmDelivery ? (
            <div
              style={
                distanceInKmVerify(proposalInfo?.distanceInKmDelivery)
                  ? textStyleNegative
                  : textStyle
              }
            >
              <strong>Distância de entrega:</strong>{" "}
              {proposalInfo?.distanceInKmDelivery} Km
            </div>
          ) : (
            <></>
          )}
          {proposalInfo?.distanceInKm ? (
            <div
              style={
                distanceInKmVerify(proposalInfo?.distanceInKm)
                  ? textStyleNegative
                  : textStyle
              }
            >
              <strong>Distância:</strong> {proposalInfo?.distanceInKm} Km
            </div>
          ) : (
            <></>
          )}

          <div style={textStyle}>
            <strong>Valor Total:</strong> {proposalInfo.totalPriceMoeda}
          </div>
        </div>

        {proposalInfo?.dimensions?.length ? (
          <>
            <h6 style={subtitleStyle}>Dimensões dos Volumes:</h6>
            <table style={tableStyle}>
              <thead>
                <tr>
                  <th style={tableHeaderStyle}>Tipo de Embalagem</th>
                  <th style={tableHeaderStyle}>Altura (m)</th>
                  <th style={tableHeaderStyle}>Largura (m)</th>
                  <th style={tableHeaderStyle}>Comprimento (m)</th>
                  <th style={tableHeaderStyle}>Cubagem (m³)</th>
                  <th style={tableHeaderStyle}>Quantidade</th>
                </tr>
              </thead>
              <tbody>
                {proposalInfo?.dimensions.map((item, index) => (
                  <tr key={index}>
                    <td style={tableCellStyle}>{item.kindOfPacking}</td>
                    <td style={tableCellStyle}>{item.height}</td>
                    <td style={tableCellStyle}>{item.width}</td>
                    <td style={tableCellStyle}>{item.length}</td>
                    <td style={tableCellStyle}>{item.cubage}</td>
                    <td style={tableCellStyle}>{item.quantity}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : (
          <></>
        )}

        <h6 style={subtitleStyle}>Informações Adicionais:</h6>
        <div style={sectionStyle}>
          {fields.map((field, index) => {
            if (!field.condition) return null;

            return (
              <div key={index} style={field.style}>
                <strong>{field.label}:</strong> {field.value}
              </div>
            );
          })}
        </div>

        <AdditionalValues onAdd={setValues} id={idProposal} />
      </div>
      <div style={{ marginTop: "30px", textAlign: "end" }}>
        <Button
          padding={"5px 20px"}
          background={"#123164"}
          borderRadius="10px"
          marginTop="0"
          name={"Adicionar valores"}
          color={"#fff"}
          onClick={apply}
          border="1px solid #FFE1E1"
        />
      </div>
    </div>
  );
};

export default CardAdditional;
