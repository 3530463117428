import React, { useState } from "react";
import SelectPrimeComponent from "./SelectPrimeComponent";
import InputPrimeReact from "./InputPrimeReact";

const MerchandiseDetails = ({ formData, setFormData }) => {
  // Função genérica para atualizar o estado
  const handleChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // Definindo opções para os selects
  const simNaoOptions = [
    { label: "Sim", value: "Sim" },
    { label: "Não", value: "Não" },
  ];

  const tipoEmbalagemOptions = [
    { label: "Amarrados", value: "Amarrados" },
    { label: "Big bag", value: "Big bag" },
    { label: "Bobina", value: "Bobina" },
    { label: "Caixas de madeira", value: "Caixas de madeira" },
    { label: "Caixas de papelão", value: "Caixas de papelão" },
    { label: "Cilindros", value: "Cilindros" },
    { label: "Container", value: "Container" },
    { label: "Engradado", value: "Engradado" },
    { label: "Engradado de madeira", value: "Engradado de madeira" },
    { label: "Estrados", value: "Estrados" },
    { label: "Estrados de madeira", value: "Estrados de madeira" },
    { label: "Fardos", value: "Fardos" },
    { label: "IBC", value: "IBC" },
    { label: "Pallet de plástico", value: "Pallet de plástico" },
    { label: "Pallets de madeira", value: "Pallets de madeira" },
    { label: "Rolos", value: "Rolos" },
    { label: "Sacos", value: "Sacos" },
    { label: "Tambores de plástico", value: "Tambores de plástico" },
    { label: "Tambores de ferro", value: "Tambores de ferro" },
    { label: "Outros", value: "Outros" },
  ];

  const moedaOptions = [
    { label: "Real", value: "BRL" },
    { label: "Dólar", value: "USD" },
    { label: "Euro", value: "EUR" },
  ];

  return (
    <div
      className="merchandise-details-grid"
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: "20px",
        width: "100%",
        marginBottom: 30,
      }}
    >
      {/* Tipo da mercadoria: Input */}
      <InputPrimeReact
        label="Tipo da Mercadoria"
        type="text"
        value={formData.merchandiseType}
        onChange={(e) => handleChange("merchandiseType", e.target.value)}
        placeholder="Digite o tipo da mercadoria"
        className={"inputMerchandise"}
        width={"300px"}
      />

      {/* Carga paletizada: Select - Sim ou Não */}
      <SelectPrimeComponent
        label="Carga Paletizada"
        options={simNaoOptions}
        placeholder="Selecione"
        selectedValue={formData.palletizedLoad}
        onChange={(value) => handleChange("palletizedLoad", value)}
        width={300}
        searchable={false}
      />

      {/* Tipo da embalagem: Select */}
      <SelectPrimeComponent
        label="Tipo da Embalagem"
        options={tipoEmbalagemOptions}
        placeholder="Selecione o tipo da embalagem"
        selectedValue={formData.packagingType}
        onChange={(value) => handleChange("packagingType", value)}
        width={300}
        searchable={true}
      />

      {/* A carga empilhada: Select - Sim ou Não */}
      <SelectPrimeComponent
        label="A Carga Empilhada"
        options={simNaoOptions}
        placeholder="Selecione"
        selectedValue={formData.stackedLoad}
        onChange={(value) => handleChange("stackedLoad", value)}
        width={300}
        searchable={false}
      />

      {/* Moeda: Select */}
      <SelectPrimeComponent
        label="Moeda"
        options={moedaOptions}
        placeholder="Selecione a moeda"
        selectedValue={formData.currency}
        onChange={(value) => handleChange("currency", value)}
        width={300}
        searchable={false}
      />

      {/* Valor da mercadoria: Input de preço */}
      <InputPrimeReact
        label="Valor da Mercadoria"
        type="currency"
        onChange={(value) => handleChange("merchandiseValue", value)}
        currencyType={formData.currency || "BRL"}
        placeholder="0,00"
        className={"currencyMerchandise"}
      />
    </div>
  );
};

export default MerchandiseDetails;
