export const estadosArgentina = {
  states: [
    {
      id: 1,
      name: "Buenos Aires",
      sigla: "Buenos Aires",
      cities: [
        { id: 1, name: "La Plata" },
        { id: 2, name: "Mar del Plata" },
        { id: 3, name: "Bahía Blanca" },
        { id: 4, name: "Tandil" },
        { id: 5, name: "Olavarría" },
        { id: 6, name: "Quilmes" },
        { id: 7, name: "Avellaneda" },
        { id: 8, name: "Lanús" },
        { id: 9, name: "San Isidro" },
        { id: 10, name: "Lomas de Zamora" },
        { id: 11, name: "Morón" },
        { id: 12, name: "San Fernando" },
        { id: 13, name: "Berazategui" },
        { id: 14, name: "Tres de Febrero" },
        { id: 15, name: "General Pacheco" },
        { id: 16, name: "Tigre" },
        { id: 17, name: "Pergamino" },
        { id: 18, name: "Zárate" },
        { id: 19, name: "Campana" },
        { id: 20, name: "Pilar" },
        { id: 21, name: "Junín" },
        { id: 22, name: "Necochea" },
        { id: 23, name: "Balcarce" },
        { id: 24, name: "Chivilcoy" },
        { id: 25, name: "San Nicolás de los Arroyos" },
        { id: 26, name: "Mercedes" },
        { id: 27, name: "Azul" },
        { id: 28, name: "San Pedro" },
        { id: 29, name: "Cañuelas" },
        { id: 30, name: "Monte Grande" },
        { id: 31, name: "Ramos Mejía" },
        { id: 32, name: "Merlo" },
        { id: 33, name: "Moreno" },
        { id: 34, name: "Vicente López" },
        { id: 35, name: "General Rodríguez" },
        { id: 36, name: "Pinamar" },
        { id: 37, name: "Villa Gesell" },
        { id: 38, name: "Luján" },
        { id: 39, name: "Florencio Varela" },
      ],
    },
  ],
};
