export const yesOrNoAnswer = [
  { label: "Selecione", value: null },
  { label: "Sim", value: true },
  { label: "Não", value: false },
];

export const yesOrNoAnswerNotDefault = [
  { label: "Sim", value: true },
  { label: "Não", value: false },
];
