import React from "react";
import InputSelectFilterManager from "./InputSelectFilterManager";
import Input from "./Input";

const AdditionalServices = ({
  servicesAdd,
  formData,
  setFormData,
  detailsQuotation,
  incontermsLTL,
}) => {
  // Função para atualizar o formData
  const handleChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const boxInputs = {
    display: "flex",
    gap: "20px",
  };

  const inputActive = true;

  return (
    servicesAdd && (
      <>
        <h3 className="textTitleTypeDispatch">
          Serviços adicionais - Brasil (Opcional)
        </h3>

        <div style={{ ...boxInputs, margin: "20px 0 0 0" }}>
          {/* Emissão DUE/Desembaraço */}
          <InputSelectFilterManager
            isActive={inputActive}
            onChange={(e) => handleChange("emissionClearance", e.target.value)}
            color="var(--grey-1)"
            name={`${
              detailsQuotation.routeType === "Nacional"
                ? "Emissão DUE-Desembaraço"
                : "Desembaraço aduaneiro export/import"
            }?`}
            width="300px"
            top="50px"
            custom="false"
            type="boolean"
            selectedValue={formData.emissionClearance}
          />

          <Input
            isActive={false}
            value={formData.emissionClearancePrice}
            onChange={(e) =>
              handleChange("emissionClearancePrice", e.target.value)
            }
            name={`Valor ${incontermsLTL === "EXW" ? "Incluso" : "da emissão"}`}
            width="150px"
            placeholder="0,00"
            type={"text"}
            marginBottom="10px"
          />

          {/* Emissão Certificado de Origem */}
          <InputSelectFilterManager
            isActive={inputActive}
            onChange={(e) =>
              handleChange("issuanceCertifOrigin", e.target.value)
            }
            color="var(--grey-1)"
            name="Emissão CO_certif origem ?"
            width="270px"
            top="50px"
            custom="false"
            type="boolean"
            selectedValue={formData.issuanceCertifOrigin}
          />

          <Input
            isActive={false}
            value={formData.issuanceCertifOriginPrice}
            onChange={(e) =>
              handleChange("issuanceCertifOriginPrice", e.target.value)
            }
            name={`Valor ${incontermsLTL === "EXW" ? "Incluso" : "da emissão"}`}
            width="150px"
            placeholder="0,00"
            type={"text"}
            marginBottom="10px"
          />
        </div>

        {/* Emissão CO adicional e ajudantes */}
        <div style={boxInputs}>
          <div style={{ display: "flex", width: "100%", gap: "20px" }}>
            <InputSelectFilterManager
              isActive={inputActive}
              onChange={(e) =>
                handleChange("additionalIssuanceCertifOrigin", e.target.value)
              }
              color="var(--grey-1)"
              name="Emissão CO_certif origem adicional ?"
              width="300px"
              top="50px"
              custom="false"
              type="boolean"
              selectedValue={formData.additionalIssuanceCertifOrigin}
            />

            <Input
              isActive={false}
              value={formData.additionalIssuanceCertifOriginPrice}
              onChange={(e) =>
                handleChange(
                  "additionalIssuanceCertifOriginPrice",
                  e.target.value
                )
              }
              name={`Valor ${
                incontermsLTL === "EXW" ? "Incluso" : "da emissão"
              }`}
              width="150px"
              placeholder="0,00"
              type={"text"}
              marginBottom="10px"
            />
          </div>

          <div style={{ width: "100%", display: "flex", gap: "20px" }}>
            {/* Ajudantes */}
            <InputSelectFilterManager
              isActive={inputActive}
              onChange={(e) => handleChange("helperFee", e.target.value)}
              color="var(--grey-1)"
              name="Ajudantes ?"
              width="100%"
              top="50px"
              custom="false"
              type="boolean"
              selectedValue={formData.helperFee}
            />

            {formData.helperFee === "Sim" && (
              <InputSelectFilterManager
                isActive={inputActive}
                onChange={(e) => handleChange("helperQuantity", e.target.value)}
                color="var(--grey-1)"
                name="Quantos"
                width="100%"
                top="50px"
                custom="false"
                type="quantity"
                selectedValue={formData.helperQuantity}
              />
            )}

            <Input
              isActive={false}
              value={formData.helperFeePrice}
              onChange={(e) => handleChange("helperFeePrice", e.target.value)}
              name="Taxa por ajudante"
              width="150px"
              placeholder="0,00"
              type={"text"}
              marginBottom="10px"
            />
          </div>
        </div>
      </>
    )
  );
};

export default AdditionalServices;
