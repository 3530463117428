import React from "react";

const RadioPrimeComponent = ({
  name,
  options,
  onChange,
  label,
  selectedValue,
  gap = "5px",
}) => {
  const handleChange = (value) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
      <label>{label}</label>
      <div style={{ display: "flex", gap: "10px" }}>
        {options.map((option) => (
          <label
            key={option.value}
            style={{
              display: "flex",
              alignItems: "center",
              gap: gap,
              cursor: "pointer",
            }}
          >
            <input
              type="radio"
              name={name}
              value={option.value}
              checked={selectedValue === option.value}
              onChange={() => handleChange(option.value)}
              style={{ cursor: "pointer" }}
            />
            {option.label}
          </label>
        ))}
      </div>
    </div>
  );
};

export default RadioPrimeComponent;
