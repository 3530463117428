import Button from "./Button";
import banner from "../images/svg/bannerModalCompleteQuotation.svg";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const ModalCompleteQuotation = ({ onClick, additionalModal = null }) => {
  const navigate = useNavigate();

  const isProposedInAnalysis = () => {
    if (additionalModal === "Analize") return true;
    else return false;
  };

  useEffect(() => {
    console.log(isProposedInAnalysis());
  }, [additionalModal]);

  return (
    <div
      className="modalEdit"
      style={{
        width: "513px",
        height: "592px",
        backgroundColor: "#fff",
        borderRadius: "20px",
        padding: "40px 43px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        boxShadow: "0px 0px 30px -18px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "14px",
        }}
      >
        <h3 className="textTitleModalQuotation">
          {isProposedInAnalysis() ? "Proposta em análize" : "Proposta enviada"}
        </h3>
        <p className="textDescriptionModalQuotation">
          {isProposedInAnalysis()
            ? "Sua proposta foi enviada para nossa equipe e, em breve, você receberá um e-mail com a versão revisada. Além disso, você poderá visualizar sua proposta na aba de propostas. Agradecemos pela sua preferência!"
            : "Você pode acessá-la tanto pelo painel quanto pelo seu e-mail. Para ver a proposta, clique no botão abaixo."}
        </p>
      </div>

      <img src={banner} />
      {!isProposedInAnalysis() && (
        <Button
          width="315px"
          height="44px"
          background={"var(--color-primary)"}
          borderRadius="60px"
          marginTop="0"
          name="Visualizar proposta"
          color={"var(--white)"}
          onClick={() => navigate("/dashboard/proposal")}
          border="none"
        />
      )}
    </div>
  );
};

export default ModalCompleteQuotation;
