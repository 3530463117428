import React from "react";
import { yesOrNoAnswerNotDefault } from "../mocks/defaultAnswers";
import RadioPrimeComponent from "./RadioPrimeComponent";

const CollectOrNot = ({ origin, collect, onCollectChange, message, name }) => {
  return (
    <div
      style={{
        marginTop: 20,
        display: origin ? "flex" : "none",
        alignItems: "center",
        borderBottom: "1px solid #d7d7d7",
        paddingBottom: 20,
      }}
    >
      <p style={{ marginRight: "10px" }}>{message}</p>
      <div
        style={{
          display: "flex",
          gap: "20px",
          transform: "translateY(-5px)",
        }}
      >
        <RadioPrimeComponent
          name={name} // Garantindo que o nome seja único
          options={yesOrNoAnswerNotDefault}
          selectedValue={collect}
          onChange={onCollectChange}
          gap="8px"
        />
      </div>
    </div>
  );
};

export default CollectOrNot;
