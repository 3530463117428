import { useContext, useEffect, useState } from "react";
import Input from "./Input";
import InputPrimeReact from "./InputPrimeReact";
import { DashboardContext } from "../context/Dashboard/DashboardContext";
import api from "../services/api";
import ModalContainer from "./ModalContainer";
import ModalGroupingMode from "./ModalGroupingMode";

const InputsFreightQuote = ({
  sumResume,
  setSumResume,
  index,
  isActive,
  updateCubage,
  setMultiLineCubing,
  setIsMultiLineCubing,
  isMultiLineCubing,
  setDimensions,
  multiLineCubing,
}) => {
  const { update, setUpdate, cubageCheckFractional, setCubageCheckFractional } =
    useContext(DashboardContext);

  const [quantityCheckFractional, setQuantityCheckFractional] = useState("");
  const [grossWeightCheckFractional, setGrossWeightCheckFractional] =
    useState("");
  const [heightCheckFractional, setHeightCheckFractional] = useState("");
  const [widthCheckFractional, setWidthCheckFractional] = useState("");
  const [lengthCheckFractional, setLengthCheckFractional] = useState("");
  const [cubedWeight, setCubedWeight] = useState("");
  const [isModalActive, setIsModalActive] = useState(false);

  const calculateCubage = async (length, width, height) => {
    const cubage = (Number(length) * Number(width) * Number(height)) / 1000000;
    const result = cubage * quantityCheckFractional * 1000000;
    const formattedResult = result.toLocaleString("pt-BR");

    const sum = {
      cubageTotal: Number(result.toFixed(2)),
      m3: `${formattedResult} m³`,
      grossWeight: grossWeightCheckFractional,
      quantity: Number(quantityCheckFractional),
      grossWeight2: grossWeightCheckFractional,
      weightCubed2: cubedWeight,
    };

    if (sum.cubageTotal > 0) {
      try {
        const { data } = await api.post("/discounts/quotation/cubed", {
          cubageTotal: sum.cubageTotal,
        });

        const formactCubed = Number(data.result);
        console.log(formactCubed);

        sum.cubageWeight = formactCubed;
        sum.total =
          grossWeightCheckFractional > Number(formactCubed)
            ? grossWeightCheckFractional
            : Number(formactCubed);

        sum.weightCubed2 = formactCubed;
        setCubedWeight(formactCubed);
      } catch (error) {
        console.error("Error calculating cubage weight:", error);
        setCubedWeight(0);
      }
    } else {
      setCubedWeight(0);
    }

    setSumResume(sum);
    setUpdate(!update);
    setCubageCheckFractional(`${formattedResult} m³`);
  };

  useEffect(() => {
    calculateCubage(
      lengthCheckFractional,
      widthCheckFractional,
      heightCheckFractional
    );
  }, [
    quantityCheckFractional,
    grossWeightCheckFractional,
    heightCheckFractional,
    widthCheckFractional,
    lengthCheckFractional,
  ]);

  useEffect(() => {
    if (isMultiLineCubing) {
      setTimeout(() => {
        setIsModalActive(true);
      }, 500);
    }
  }, [isMultiLineCubing]);

  useEffect(() => {
    if (isMultiLineCubing) {
      const cubageMultiLine = multiLineCubing?.cubageSum
        ? `${multiLineCubing.cubageSum} m³`
        : `0 m³`;

      const cubedWeightMultiLine = multiLineCubing?.weightCubedSum || 0;

      console.log(multiLineCubing);

      setCubedWeight(cubedWeightMultiLine);
      setCubageCheckFractional(cubageMultiLine);
      setQuantityCheckFractional(multiLineCubing?.quantitySum);
    }
  }, [isMultiLineCubing, isModalActive, multiLineCubing]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", gap: "15px" }}>
        <Input
          isActive={!isMultiLineCubing && isActive}
          value={quantityCheckFractional}
          onChange={(e) => setQuantityCheckFractional(e.target.value)}
          name="Quantidade"
          type="number"
          width="100%"
        />

        <InputPrimeReact
          disabled={!isActive}
          label="Peso bruto"
          value={grossWeightCheckFractional}
          onChange={(e) => setGrossWeightCheckFractional(e.value)}
          type="number"
          suffix=" kg"
          className="pesoCubage"
        />

        <Input
          isActive={!isMultiLineCubing && isActive}
          onBlur={(e) => setHeightCheckFractional(e.target.value)}
          name="Altura (M)"
          type="number"
          width="100%"
        />

        <Input
          isActive={!isMultiLineCubing && isActive}
          onBlur={(e) => setWidthCheckFractional(e.target.value)}
          name="Largura (M)"
          type="number"
          width="100%"
        />

        <Input
          isActive={!isMultiLineCubing && isActive}
          onBlur={(e) => setLengthCheckFractional(e.target.value)}
          name="Comprimento (M)"
          type="number"
          width="100%"
        />

        <Input
          isActive={!isMultiLineCubing && isActive}
          value={cubageCheckFractional}
          onChange={(e) => setCubageCheckFractional(e.target.value)}
          name="Cubagem M³"
          type="text"
          width="100%"
        />

        <InputPrimeReact
          disabled
          label="Peso cubado"
          value={cubedWeight}
          type="number"
          suffix=" kg"
          className="cubage"
        />
      </div>
      <div
        style={{
          marginBottom: "15px",
          display: "flex",
          alignItems: "center",
          gap: "10px",
          justifyContent: "end",
        }}
      >
        <input
          type="checkbox"
          checked={isMultiLineCubing}
          onChange={() => setIsMultiLineCubing(!isMultiLineCubing)}
          style={{ cursor: "pointer" }}
        />
        <span>Adicionar múltiplas dimensões?</span>
      </div>

      <ModalContainer
        isActive={isModalActive}
        closeModal={() => setIsModalActive(false)}
        opacity={60}
      >
        <ModalGroupingMode
          setMultiLineCubing={setMultiLineCubing}
          setDimensions={setDimensions}
          national
          closeModal={() => setIsModalActive(false)}
        />
      </ModalContainer>
    </div>
  );
};

export default InputsFreightQuote;
