import { useContext, useState } from "react";
import Input from "./Input";
import InputSelectFilterManager from "./InputSelectFilterManager";
import InputsFreightQuote from "./InputsFreightQuote";
import Textarea from "./Textarea";
import UploadImg from "./UploadImg";
import { DashboardContext } from "../context/Dashboard/DashboardContext";
import { useEffect } from "react";
import api from "../services/api";
import axios from "axios";
import InputsFreightQuoteInternational from "./InputsFreightQuoteInternational";
import { toast } from "react-toastify";
import { ProposalContext } from "../context/Dashboard/ProposalContext";
import InputPrimeReact from "./InputPrimeReact";
import { estadosArgentina } from "../mocks/estados.argentina";
import SelectPrimeComponent from "./SelectPrimeComponent";
import { inconterms } from "../mocks/inconterms";
import {
  yesOrNoAnswer,
  yesOrNoAnswerNotDefault,
} from "../mocks/defaultAnswers";
import RadioPrimeComponent from "./RadioPrimeComponent";
import CollectOrNot from "./CollectOrNot";
import CollectionOrDelivery from "./CollectionOrDelivery";
import DeliverOrPickup from "./DeliverOrPickup";
import MerchandiseDetails from "./MerchandiseDetails";
import AdditionalServices from "./AdditionalServices";
// import InputSelectPrimeReact from "./InputSelectPrimeReact";

const CardInfoQuotationInternational = ({
  inconterm,
  type,
  index,
  imo,
  servicesAdd,
}) => {
  const {
    getOrigin,
    getDestination,
    dataCheckFractional,
    setDataCheckFractional,
  } = useContext(DashboardContext);

  const { btnStateInternationLTL } = useContext(ProposalContext);

  /* DATA LOCALE */
  const [dataLocation, setDataLocation] = useState({});

  /* INPUTS VALUE */
  const [originFraction, setOriginFraction] = useState("");
  const [originDestination, setOriginDestination] = useState("");
  const [originDestinationExemplo, setOriginDestinationExemplo] = useState("");

  const [selectDestination, setSelectDestination] = useState([]);

  // NOVO ----------------------------------------------------------------
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [originOptionsLtl, setOriginOptionsLtl] = useState([]);

  const [destinationOptionsLtl, setDestinationOptionsLtl] = useState([]);
  const [incontermsLTL, setIncontermsLTL] = useState("");
  const [imoLTL, setImoLTL] = useState("");
  const [collect, setCollect] = useState(false);
  const [deliver, setDeliver] = useState(false);

  const [cityCollect, setCityCollect] = useState("");
  const [addressCollect, setAddressCollect] = useState("");

  const [cityDeliver, setCityDeliver] = useState("");
  const [addressDeliver, setAddressDeliver] = useState("");

  const [detailsQuotation, setDetailsQuotation] = useState({
    origin: "",
    destiny: "",
    storage: "",
    address: "",
    addressImo: "",

    collectionAddress: "",
    collectionAddressImo: "",
    deliveryAddress: "",
    deliveryAddressImo: "",
    city: "",
    cityImo: "",
    finalDestination: "",
    discount: 0,
    increase: 0,
    routeType: "Nacional",
  });

  const [merchandiseDetails, setMerchandiseDetails] = useState({
    merchandiseType: "",
    palletizedLoad: null,
    packagingType: null,
    stackedLoad: null,
    currency: null,
    merchandiseValue: null,
  });

  const [dataSummaryResume, setDataSummaryResume] = useState({
    cubageTotal: 0,
    m3: "0 m³",
    grossWeight: 0,
    quantity: 0,
    grossWeight2: 0,
    weightCubed2: 0,
    cubageWeight: 0,
    total: 0,
  });

  const [additionalServices, setAdditionalServices] = useState({
    emissionClearance: null,
    emissionClearancePrice: "",
    issuanceCertifOrigin: null,
    issuanceCertifOriginPrice: "",
    additionalIssuanceCertifOrigin: null,
    additionalIssuanceCertifOriginPrice: "",
    helperFee: null,
    helperQuantity: "",
    helperFeePrice: "",
  });

  // ---------------------------------------------------------------------

  const [inputActive, setInputActive] = useState(false);
  const [commodityPrice, setCommodityPrice] = useState(0);
  const [merchandiseFraction, setMerchandiseFraction] = useState("");

  const [imagesBase64, setImagesBase64] = useState([]);
  const [imagesFirebase, setImagesFirebase] = useState([]);

  /* QUOTATION SUMMARY */
  const [freightWeightValue, setFreightWeightValue] = useState(0);
  const [dispatchValue, setdispatchValue] = useState(0);
  const [sefazTaxValue, setSefazTaxValue] = useState(0);

  const [advaloremValue, setAdvaloremValue] = useState(0);
  const [tollValue, setTollValue] = useState(0);

  const [cubageTotal, setCubageTotal] = useState(0);

  const [updateValue, setUpdateValue] = useState(false);
  const [cityList, setCityList] = useState([]);

  const [statesList, setStatesList] = useState([]);
  const [originInternarional, setOriginInternarional] = useState("");
  const [destinationInternational, setDestinationInternational] = useState(""); // REMOVER

  const [incontermInternational, setIncontermInternational] = useState("");
  const [imoInternational, setImoInternational] = useState("");
  const [originFractionInternational, setOriginFractionInternational] =
    useState("");

  const [stateSelectUser, setStateSelectUser] = useState("");

  const [priceTotalStanded, setPriceTotalStanded] = useState("");
  const [priceTotalPremium, setPriceTotalPremium] = useState("");

  const [addressInternarional, setAddressInternarional] = useState("");
  const [addressInternarionalState, setAddressInternarionalState] =
    useState("");
  const [addressInternarionalOptional, setAddressInternarionalOptional] =
    useState("");

  const [
    addressInternarionalOptionalActive,
    setAddressInternarionalOptionalActive,
  ] = useState(false);

  const [kindOfPacking, setKindOfPacking] = useState("");
  const [palletizedLoad, setPalletizedLoad] = useState("");
  const [stackedLoad, setStackedLoad] = useState("");

  const [coinType, setCoinType] = useState("");
  const [originOption, setOriginOption] = useState([]); // REMOVER

  const [emissionClearancePrice, setEmissionClearancePrice] = useState("");
  const [issuanceCertifOriginPrice, setIssuanceCertifOriginPrice] =
    useState("");
  const [
    additionalIssuanceCertifOriginPrice,
    setAdditionalIssuanceCertifOriginPrice,
  ] = useState("");

  const [helperFeePrice, setHelperFeePrice] = useState("");
  const [hasRenderedOnce, setHasRenderedOnce] = useState(false);

  const [allStatesAndCitysToBrazil, setAllStatesAndCitysToBrazil] = useState(
    []
  );
  const [disableStateSelection, setDisableStateSelection] = useState(false);

  const [formOfPayment, setFormOfPayment] = useState("");

  const [multiLineCubing, setMultiLineCubing] = useState("");
  const [isMultiLineCubing, setIsMultiLineCubing] = useState(false);
  const [dimensions, setDimensions] = useState([]);

  const [isAduanaBuenos, setIsAduanaBuenos] = useState(false);
  const [visibleOriginBuenos, setVisibleOriginBuenos] = useState(true);
  const [collectionAddress, setCollectionAddress] = useState("");
  const [isDisableOrigin, setIsDisableOrigin] = useState(false);

  const getSelect = async (e) => {
    if (e.target.value != "default") {
      // USAR COM PRIME REACT
      const result = await getOrigin(e.value?.nome ?? e.value?.name, index);

      setOriginDestination(e.value.nome);

      setOriginDestinationExemplo(e.value);

      // const result = await getOrigin(e.target.value, index);

      // setOriginDestination(e.target.value);

      // setOriginDestinationExemplo(e.target.value);

      setSelectDestination({
        option: result.national,
      });
    } else {
      setSelectDestination({});
    }
  };

  // FUNÇÃO DE ENVIAR ------------------------------------------------------------
  const getTotalValues = async () => {
    let dataState = dataCheckFractional;

    dataState[index] = {
      origem: origin,
      destino: destination,
      inconterm: incontermsLTL,
      cargaImo: imoLTL,
      coleta: collect,
      entrega: deliver,
      cidadeColeta: cityCollect,
      enderecoColeta: addressCollect,
      cidadeEntrega: cityDeliver,
      enderecoEntrega: addressDeliver,
      tipoMercadoria: merchandiseDetails.merchandiseType,
      cargaPaletizada: merchandiseDetails.palletizedLoad,
      tipoEmbalagem: merchandiseDetails.packagingType,
      cargaEmpilhada: merchandiseDetails.stackedLoad,
      moeda: merchandiseDetails.currency,
      valorMercadoria: merchandiseDetails.merchandiseValue,
      quantidade: dataSummaryResume?.quantity,
      peso: Math.max(dataSummaryResume?.total, dataSummaryResume?.cubageWeight),
      pesoCliente: dataSummaryResume?.grossWeight,
      cubagem: dataSummaryResume?.m3,
      cubageNumero: dataSummaryResume.cubageTotal,
      emissaoDue: additionalServices.emissionClearance,
      emissaoCertificado: additionalServices.issuanceCertifOrigin,
      emissaoCertificadoAdicional:
        additionalServices.additionalIssuanceCertifOrigin,
      ajudantes: additionalServices.helperFee,
      ajudantesQTD: additionalServices.helperQuantity,
      multiplasCubagens: {
        quantidade: multiLineCubing?.quantitySum,
        peso: multiLineCubing?.weightCubedSum,
      },
      dimenssoes: dimensions,
      formaPagamento: formOfPayment,
      type: type,
    };

    console.log(dataState[index]);

    if (isMultiLineCubing) {
      dataState[index].peso = multiLineCubing?.weightCubedSum;
      dataState[index].cubagem = `${Number(multiLineCubing?.cubageSum).toFixed(
        2
      )} m³`;
      dataState[index].quantidade = multiLineCubing?.quantitySum;
      dataState[index].tipoEmbalagem =
        multiLineCubing?.hasWood == true ? "Caixas de madeira" : kindOfPacking;
    }

    try {
      const { data: dataResponse } = await api.post(
        "/quotations/international/all",
        dataState[index]
      );

      console.log(dataResponse);

      if (dataResponse?.message == "Alguém do comercial entrara em contato") {
        return toast.info(
          "Sua cotação está fora do nosso raio de atendimento. Nosso consultor entrara em contato, após ser concluída essa cotação.",
          {
            position: "top-right",
            autoClose: 7000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      } else if (dataResponse?.exceededKmLimit) {
        setdispatchValue(0);
        setSefazTaxValue(0);
        setEmissionClearancePrice("");

        setIssuanceCertifOriginPrice("");
        setAdditionalIssuanceCertifOriginPrice("");
        setHelperFeePrice();

        setCubageTotal(0);
        setPriceTotalStanded();
        setPriceTotalPremium("");
      } else {
        setdispatchValue(dataResponse?.freight);
        setSefazTaxValue(dataResponse?.adValorem);

        setAdditionalServices({
          ...additionalServices,
          emissionClearancePrice: dataResponse?.dueClearanceRequired,
          issuanceCertifOriginPrice: dataResponse?.coCertificateOrigin,
          additionalIssuanceCertifOriginPrice:
            dataResponse?.additionalCoCertificateOrigin,
          helperFeePrice: dataResponse?.helperFee,
        });

        setCubageTotal(dataResponse?.cubage);
        setPriceTotalStanded(dataResponse?.totalPriceMoeda);
        setPriceTotalPremium(dataResponse?.totalPriceMoedaPremium);
      }

      dataState[index].palletizedLoadShoose =
        dataResponse?.palletizedLoadShoose;
      dataState[index].hazardousCargoShoose =
        dataResponse?.hazardousCargoShoose;
      dataState[index].stackedLoadShoose = dataResponse?.stackedLoadShoose;
      dataState[index].coinTypeShoose = dataResponse?.coinTypeShoose;
      dataState[index].emissionClearanceShoose =
        dataResponse?.emissionClearanceShoose;
      dataState[index].issuanceCertifOriginShoose =
        dataResponse?.issuanceCertifOriginShoose;
      dataState[index].additionalIssuanceCertifOriginShoose =
        dataResponse?.additionalIssuanceCertifOriginShoose;
      dataState[index].additionalIssuanceCertifOrigin =
        dataResponse?.additionalIssuanceCertifOrigin;
      dataState[index].helperFeeShoose = dataResponse?.helperFeeShoose;

      dataState[index].quantityHelperFeeShoose =
        dataResponse?.quantityHelperFeeShoose;
      dataState[index].freight = dataResponse?.freight;
      dataState[index].adValorem = dataResponse?.adValorem;
      dataState[index].dueClearanceRequired =
        dataResponse?.dueClearanceRequired;
      dataState[index].coCertificateOrigin = dataResponse?.coCertificateOrigin;
      dataState[index].additionalCoCertificateOrigin =
        dataResponse?.additionalCoCertificateOrigin;
      dataState[index].helperFee = dataResponse?.helperFee;
      dataState[index].cubage = dataResponse?.cubage;
      dataState[index].coinToday = dataResponse?.coinToday;
      dataState[index].totalPriceMoeda = dataResponse?.totalPriceMoeda;
      dataState[index].peso = dataResponse?.peso;
      dataState[index].totalPriceMoedaPremium =
        dataResponse?.totalPriceMoedaPremium;
      dataState[index].routeOrigin = dataResponse?.routeOrigin;
      dataState[index].routeDest = dataResponse?.routeDest;
      dataState[index].exceededKmLimit = dataResponse?.exceededKmLimit;
      // dataState[index].distanceInKm = dataResponse?.distanceInKm; --- REMOVER
      dataState[index].distanceInKmPickup = dataResponse?.distanceInKmPickup;
      dataState[index].distanceInKmDelivery =
        dataResponse?.distanceInKmDelivery;
      dataState[index].argentinaEXW = dataResponse?.argentinaEXW;
      dataState[index].inconterm = inconterm;
    } catch (error) {
      console.log(error);
      const message = error.response.data.message;

      return toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    setDataCheckFractional(dataState);
  };

  const getStatesInBrazil = async () => {
    try {
      const getStates = await axios.get(
        "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
      );

      setStatesList(getStates.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCityInBrazil = async (e, stateFormact) => {
    try {
      const state = e?.target?.value ?? stateFormact;
      console.log(stateFormact, 100);
      setStateSelectUser(state);
      // setOriginDestination("");

      if (originInternarional.toLowerCase()?.includes("buenos")) {
        setCityList(state.cities);
      } else {
        const getCitys = await axios.get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state.sigla}/municipios?orderBy=nome`
        );

        setCityList(getCitys.data);
      }
    } catch (error) {}
  };

  // NOVO
  const getOriginsLTL = async () => {
    try {
      const { data } = await api.get("/quotations/international/origin");

      const formact = data?.map((elem) => ({
        label: elem.origin,
        value: elem.origin,
      }));

      setOriginOptionsLtl(formact);
    } catch (error) {}
  };

  // NOVO
  const getDestinationLTL = async (originSelect) => {
    try {
      const { data } = await api.get(
        `/quotations/international/destinations?origin=${originSelect}`
      );

      const formact = data?.map((elem) => ({
        label: elem.destiny.split(),
        value: elem.destiny,
      }));

      setOrigin(originSelect);
      setDestinationOptionsLtl(formact);
    } catch (error) {}
  };

  // NOVO
  const getIdDetailsCotation = async (destiny) => {
    setDestination(destiny);
    try {
      const { data } = await api.get(
        `/quotations/international/details?origin=${origin}&destiny=${destiny}`
      );

      console.log(data);
      setDetailsQuotation(data);
    } catch (error) {}
  };

  // REMOVER
  const getOrigins = async (e) => {
    try {
      const response = await api.get("/quotations/international/origin");

      const formactValues = response?.data.map((elem) => {
        const destiny =
          elem?.destiny?.split("–")[0] ?? elem?.destiny?.split("-")[0];
        return { ...elem, destiny };
      });

      setOriginOption(formactValues);
    } catch (error) {
      console.error(error);
    }
  };

  // Função para buscar a lista de municípios do IBGE
  const fetchStates = async () => {
    try {
      const response = await axios.get(
        "https://servicodados.ibge.gov.br/api/v1/localidades/municipios"
      );
      const municipios = response.data;

      setAllStatesAndCitysToBrazil(municipios);
    } catch (error) {
      console.error("Erro ao buscar estados:", error);
    }
  };

  // Função para remover pontuação
  const removePunctuation = (text) => {
    return text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^\w\s]/gi, "")
      .toLowerCase();
  };

  // FUNÇÃO DEIXA PRE SETADO O ESTADO NO CAMPO 'SELECIONAR ESTADO'
  const onSelectStateOrigin = () => {
    const nameClean = removePunctuation(originInternarional);

    if (nameClean?.includes("outros")) {
      setDisableStateSelection(false);
      return false;
    }

    const filterStateSelect = allStatesAndCitysToBrazil.find(
      (states) => removePunctuation(states.nome) === nameClean
    );

    const formactState = filterStateSelect?.microrregiao?.mesorregiao?.UF;

    if (formactState) {
      setStateSelectUser(formactState);
      setDisableStateSelection(true);
      getCityInBrazil("", formactState);
    } else setDisableStateSelection(false);
  };

  // FUNÇÃO DEIXA PRE SETADO O ESTADO NO CAMPO 'SELECIONAR ESTADO'
  const onSelectStateDestination = () => {
    if (originFractionInternational === "Coletar") {
      const nameClean = removePunctuation(destinationInternational?.trim());

      console.log(nameClean, "<==== DESTINO");

      if (nameClean.includes("outros")) {
        setDisableStateSelection(false);
        return false;
      }

      const filterStateSelect = allStatesAndCitysToBrazil.find(
        (states) => removePunctuation(states.nome) === nameClean
      );

      const formactState = filterStateSelect?.microrregiao?.mesorregiao?.UF;

      if (formactState) {
        setStateSelectUser(formactState);
        setDisableStateSelection(true);
        getCityInBrazil("", formactState);
      } else setDisableStateSelection(false);
    }
  };

  useEffect(() => {
    originInternarional && onSelectStateOrigin();
  }, [originInternarional]);

  useEffect(() => {
    destinationInternational && onSelectStateDestination();
  }, [destinationInternational, originFractionInternational]);

  useEffect(() => {
    if (
      originFractionInternational == "Coletar | Pick-up" ||
      originFractionInternational == "Coletar"
    ) {
      setAddressInternarionalOptionalActive(true);
    } else {
      setAddressInternarionalOptionalActive(false);
    }
  }, [originFractionInternational]);

  useEffect(() => {
    setCommodityPrice("");
  }, [coinType]);

  // useEffect(() => {
  //   setInputActive(true);
  //   if (originOption.length > 0) {
  //     originOption.map((item) => {
  //       if (item?.origin?.includes(originInternarional)) {
  //         console.log(item);
  //         if (imoInternational === "Sim") {
  //           console.log(item.cityImo, 6666);
  //           setOriginFraction(item.cityImo);
  //           setAddressInternarionalState(item.finalDestination);
  //           setAddressInternarional(item.addressImo);
  //           setInputActive(true);
  //         } else {
  //           setOriginFraction(item.city);
  //           setAddressInternarionalState(item.finalDestination);
  //           setAddressInternarional(item.address);
  //           setInputActive(true);
  //         }
  //       }
  //     });
  //   } else {
  //     setInputActive(true);
  //     setAddressInternarional("");
  //     setAddressInternarionalState("");
  //   }

  //   if (
  //     originInternarional != "default" &&
  //     originInternarional != "" &&
  //     destinationInternational != "default" &&
  //     destinationInternational != "" &&
  //     incontermInternational != "default" &&
  //     incontermInternational != "" &&
  //     imoInternational != "default" &&
  //     imoInternational != "" &&
  //     originFractionInternational != "default" &&
  //     originFractionInternational != "" &&
  //     stateSelectUser != "default" &&
  //     stateSelectUser != "" &&
  //     originFraction
  //   ) {
  //     setInputActive(true);
  //   }
  // }, [
  //   originInternarional,
  //   destinationInternational,
  //   incontermInternational,
  //   imoInternational,
  //   originFractionInternational,
  //   stateSelectUser,
  //   originFraction,
  // ]);

  useEffect(() => {
    if (originOption.length > 0) {
      originOption.map((item) => {
        if (item.origin.includes(originInternarional)) {
          console.log(item.city);

          if (imoInternational === "Sim") {
            setOriginFraction(item.cityImo);
            setAddressInternarional(item.addressImo);
            setInputActive(true);
          } else {
            setOriginFraction(item.city);
            setAddressInternarional(item.address);
            setInputActive(true);
          }
        }
      });
    } else {
      setInputActive(false);
      setAddressInternarional("");
      setAddressInternarionalState("");
    }
  }, [imoInternational]);

  useEffect(() => {
    getStatesInBrazil();
    getOrigins();
    getOriginsLTL();
    fetchStates();
  }, []);

  useEffect(() => {
    console.log(
      dataSummaryResume,
      multiLineCubing,
      originFractionInternational
    );
  }, [dataSummaryResume, multiLineCubing, originFractionInternational]);

  useEffect(() => {
    if (hasRenderedOnce) {
      getTotalValues();
    }
    setHasRenderedOnce(true);
  }, [btnStateInternationLTL]);

  useEffect(() => {
    if (originFractionInternational === "Aduana fiscal – Buenos Aries") {
      setIsAduanaBuenos(true);
      setCollectionAddress("");
    } else {
      setIsAduanaBuenos(false);
    }
  }, [originFractionInternational]);

  useEffect(() => {
    if (
      incontermInternational === "EXW" &&
      originInternarional === "Buenos Aries"
    ) {
      setVisibleOriginBuenos(false);
      setIsAduanaBuenos(false);
    } else {
      setVisibleOriginBuenos(true);
    }
  }, [incontermInternational, originInternarional]);

  useEffect(() => {
    console.log(merchandiseDetails);
  }, [merchandiseDetails]);

  const boxInputs = {
    display: "flex",
    gap: "20px",
  };

  const containerContent = {
    backgroundColor: "#fff",
    width: "100%",
    padding: "32px",
    border: "1px solid #D7D7D7",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    marginTop: "30px",
  };

  return (
    <>
      <h3 className="textTitleTypeDispatch">Informações da cotação</h3>
      <div style={{ ...boxInputs, marginTop: "20px" }}>
        <SelectPrimeComponent
          label={"Rota comercial de origem"}
          options={originOptionsLtl}
          onChange={getDestinationLTL}
        />
        <SelectPrimeComponent
          label={"Rota comercial de destino"}
          options={destinationOptionsLtl}
          onChange={getIdDetailsCotation}
        />

        {inconterm && (
          <SelectPrimeComponent
            label={"Inconterms"}
            options={inconterms}
            onChange={setIncontermsLTL}
            searchable
            width={230}
          />
        )}
        {imo && (
          <SelectPrimeComponent
            label={"Carga imo"}
            options={yesOrNoAnswer}
            onChange={setImoLTL}
            width={140}
          />
        )}
      </div>

      <CollectOrNot
        origin={origin}
        collect={collect}
        onCollectChange={setCollect}
        message={"Deseja fazer a coleta da mercadoria?"}
        name="collectOption"
      />

      <CollectionOrDelivery
        hasCollection={collect}
        storage={detailsQuotation.storage}
        storageAddress={
          imoLTL
            ? detailsQuotation.collectionAddressImo
            : detailsQuotation.collectionAddress
        }
        city={cityCollect}
        setCity={setCityCollect}
        address={addressCollect}
        setAddress={setAddressCollect}
        typeDestiny={"Origem da mercadoria"}
      />
      <CollectOrNot
        origin={origin}
        collect={deliver}
        onCollectChange={setDeliver}
        message={"Deseja fazer a entrega da mercadoria?"}
        name="deliverOption"
      />

      <DeliverOrPickup
        hasCollection={deliver}
        storage={detailsQuotation.storage}
        storageAddress={
          imoLTL
            ? detailsQuotation.deliveryAddressImo
            : detailsQuotation.deliveryAddress
        }
        city={cityDeliver}
        setCity={setCityDeliver}
        address={addressDeliver}
        setAddress={setAddressDeliver}
        typeDestiny={"Destino da mercadoria"}
      />

      <MerchandiseDetails
        formData={merchandiseDetails}
        setFormData={setMerchandiseDetails}
      />

      <InputsFreightQuoteInternational
        updateCubage={() => setUpdateValue(!updateValue)}
        isActive={true}
        sumResume={dataSummaryResume}
        setSumResume={setDataSummaryResume}
        index={index}
        multiLineCubing={multiLineCubing}
        setMultiLineCubing={setMultiLineCubing}
        setIsMultiLineCubing={setIsMultiLineCubing}
        isMultiLineCubing={isMultiLineCubing}
        setDimensions={setDimensions}
      />

      <AdditionalServices
        formData={additionalServices}
        setFormData={setAdditionalServices}
        detailsQuotation={detailsQuotation}
        incontermsLTL={incontermsLTL}
        servicesAdd={servicesAdd}
      />

      <div style={{ marginBottom: "20px" }}>
        <h3 className="textTitleTypeDispatch">Pagamento</h3>

        <div style={{ width: "40%" }}>
          <InputPrimeReact
            label={"Escolha a forma de pagamento"}
            value={formOfPayment}
            onChange={(e) => setFormOfPayment(e.value)}
            type={"dropdown"}
            options={[
              "Boleto bancário - BRL",
              "Crédito em conta - BRL/ARS",
              "Pix à vista com desconto 1% - BRL",
              "Parcelamento 2 vezes - BRL",
              "Swift Bancário - Remessa ao exterior - USD",
              "ALIAS à vista com desconto de 1% - ARS",
            ]}
            placeholder={"Selecione"}
            width={"100%"}
            className={"currencyTable"}
            currencyType={coinType}
          />
        </div>
      </div>

      <div>
        <Textarea name="Observações adicionais" height="83px" width="100%" />
      </div>

      <UploadImg
        imagesBase64={imagesBase64}
        setImagesBase64={setImagesBase64}
        imagesFirebase={imagesFirebase}
        setImagesFirebase={setImagesFirebase}
      />

      <div style={containerContent}>
        <div>
          <h3 className="textTitleTypeDispatch">Resumo total cotação</h3>
        </div>

        <div
          style={{
            display: "flex",
          }}
        >
          <div style={{ display: "flex", width: "146px" }}>
            <div>
              <p className="textTitleSummary">Frete</p>
              <div>
                {dispatchValue ? (
                  dispatchValue
                ) : (
                  <svg
                    width="8"
                    height="3"
                    viewBox="0 0 8 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                      fill="#060606"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", gap: "18px", width: "195px" }}>
            <div>
              <svg
                width="1"
                height="50"
                viewBox="0 0 1 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="0.5"
                  y1="49.1932"
                  x2="0.5"
                  y2="0.187511"
                  stroke="#D7D7D7"
                />
              </svg>
            </div>
            <div>
              <p className="textTitleSummary">Ad Valorem | Gris</p>
              <div>
                {sefazTaxValue ? (
                  sefazTaxValue
                ) : (
                  <svg
                    width="8"
                    height="3"
                    viewBox="0 0 8 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                      fill="#060606"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", gap: "50px", width: "200px" }}>
            <div>
              <svg
                width="1"
                height="50"
                viewBox="0 0 1 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="0.5"
                  y1="49.1932"
                  x2="0.5"
                  y2="0.187511"
                  stroke="#D7D7D7"
                />
              </svg>
            </div>
            <div>
              <p className="textTitleSummary">Outros</p>
              <div>
                {freightWeightValue > 0 ? (
                  freightWeightValue.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })
                ) : (
                  <svg
                    width="8"
                    height="3"
                    viewBox="0 0 8 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                      fill="#060606"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", gap: "50px", width: "209px" }}>
            <div>
              <svg
                width="1"
                height="50"
                viewBox="0 0 1 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="0.5"
                  y1="49.1932"
                  x2="0.5"
                  y2="0.187511"
                  stroke="#D7D7D7"
                />
              </svg>
            </div>
            <div>
              <p className="textTitleSummary">Cubagem</p>
              <div>
                {cubageTotal ? (
                  cubageTotal
                ) : (
                  <svg
                    width="8"
                    height="3"
                    viewBox="0 0 8 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                      fill="#060606"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
          }}
        >
          {type === "standard" && (
            <div style={{ display: "flex", gap: "50px", width: "342px" }}>
              <div>
                <p className="textTitleSummary">
                  Valor total (Standard) | Transit time - 12 Dias
                </p>
                <div>
                  {priceTotalStanded ? (
                    priceTotalStanded
                  ) : (
                    <svg
                      width="8"
                      height="3"
                      viewBox="0 0 8 3"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                        fill="#060606"
                      />
                    </svg>
                  )}
                </div>
              </div>
            </div>
          )}

          {type === "premium" && (
            <div style={{ display: "flex", gap: "14px", width: "387px" }}>
              <div>
                <p className="textTitleSummary">
                  Valor total (Premium) | Transit time - 5 Dias
                </p>
                <span style={{ fontSize: "11px", fontWeight: "600" }}>
                  * Dead-line toda quarta feira em horário comercial
                </span>
                <div>
                  {priceTotalPremium ? (
                    priceTotalPremium
                  ) : (
                    <svg
                      width="8"
                      height="3"
                      viewBox="0 0 8 3"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                        fill="#060606"
                      />
                    </svg>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CardInfoQuotationInternational;
