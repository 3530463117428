import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";

const SelectPrimeComponent = ({
  options,
  placeholder,
  onChange,
  selectedValue,
  label,
  width,
  searchable = false, // Define se será pesquisável
  emptyMessage = "Nenhuma opção disponível", // Mensagem quando não houver itens
}) => {
  const [selectedItem, setSelectedItem] = useState(selectedValue || null);

  const handleChange = (e) => {
    setSelectedItem(e.value);
    if (onChange) {
      onChange(e.value);
    }
  };

  return (
    <div
      className="field"
      style={{ display: "flex", flexDirection: "column", gap: 12 }}
    >
      <label htmlFor="select">{label}</label>
      {searchable ? (
        <Dropdown
          id="select"
          value={selectedItem}
          options={options}
          onChange={handleChange}
          optionLabel="label"
          placeholder={placeholder || "Selecione"}
          filter
          emptyMessage={emptyMessage}
          style={{
            paddingTop: 9,
            paddingBottom: 9,
            paddingLeft: 10,
            paddingRight: 10,
            width: width ?? 250,
          }}
        />
      ) : (
        <Dropdown
          id="select"
          value={selectedItem}
          options={options}
          onChange={handleChange}
          optionLabel="label"
          placeholder={placeholder || "Selecione"}
          emptyMessage={emptyMessage} // Define a mensagem quando a lista estiver vazia
          style={{
            paddingTop: 9,
            paddingBottom: 9,
            paddingLeft: 10,
            paddingRight: 10,
            width: width ?? 250,
          }}
        />
      )}
    </div>
  );
};

export default SelectPrimeComponent;
