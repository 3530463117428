export const inconterms = [
  { label: "Selecione", value: "default" },
  { label: "EXW - Ex works", value: "EXW" },
  { label: "FCA - Free carrier", value: "FCA" },
  { label: "FAS - Free Alongside Ship", value: "FAS" },
  { label: "FOB - Free On Board", value: "FOB" },
  { label: "CPT - Carriage Paid to", value: "CPT" },
  { label: "CIP - Carriage And Insurance Paid to", value: "CIP" },
  { label: "CFR - Cost And Freight", value: "CFR" },
  { label: "CIF - Cost Insurance And Freight", value: "CIF" },
  { label: "DAP - Delivery At Place", value: "DAP" },
  { label: "DPU - Delivery At Place Unloaded", value: "DPU" },
];
