import { useState, useEffect } from "react";
import Input from "./Input";
import InputPrimeReact from "./InputPrimeReact";
import api from "../services/api";
import InputSelectFilterManager from "./InputSelectFilterManager";

const CubageRow = ({ onUpdate, row, national }) => {
  const [quantity, setQuantity] = useState("");
  const [grossWeight, setGrossWeight] = useState("");
  const [height, setHeight] = useState("");
  const [width, setWidth] = useState("");
  const [length, setLength] = useState("");
  const [cubageCheckFractional, setCubageCheckFractional] = useState("");
  const [cubedWeight, setCubedWeight] = useState("");
  const [kindOfPacking, setKindOfPacking] = useState("");

  const calculateCubage = async () => {
    const cubage = Number(length) * Number(width) * Number(height);
    const totalCubage = cubage * quantity;

    console.log(cubage, "<====", totalCubage);
    setCubageCheckFractional(totalCubage.toFixed(2));

    let weightCubed = 0;
    if (totalCubage > 0) {
      const url = national
        ? "/discounts/quotation/cubed"
        : "/discounts/quotation/cubed/international";
      const resultSum = await api.post(url, { cubageTotal: totalCubage });

      console.log(resultSum.data);

      weightCubed = Number(resultSum.data.result);
      setCubedWeight(weightCubed);
    }

    onUpdate({
      cubage: totalCubage,
      weightCubed: Math.max(grossWeight, weightCubed),
      quantity: quantity,
      kindOfPacking,
      height,
      width,
      length,
    });
  };

  useEffect(() => {
    calculateCubage();
  }, [quantity, grossWeight, height, width, length, kindOfPacking]);

  useEffect(() => {
    console.log(row);
  }, [row]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <InputSelectFilterManager
        onChange={(e) => setKindOfPacking(e.target.value)}
        color="var(--grey-1)"
        name="Tipo de embalagem"
        width="100%"
        top="50px"
        custom="false"
        type="kindOfPacking"
      />
      <div style={{ display: "flex", gap: "20px" }}>
        <Input
          onBlur={(e) => setQuantity(e.target.value)}
          name="Quantidade"
          width="100%"
          placeholder=""
          type="number"
        />
        <Input
          onBlur={(e) => setHeight(e.target.value)}
          name="Altura"
          placeholder="Ex: 1"
          width="100%"
          type="number"
        />
        <Input
          onBlur={(e) => setWidth(e.target.value)}
          name="Largura"
          placeholder="Ex: 1,50"
          width="100%"
          type="number"
        />
        <Input
          onBlur={(e) => setLength(e.target.value)}
          name="Comprimento"
          placeholder="Ex: 2"
          width="100%"
          type="number"
        />

        <Input
          value={cubageCheckFractional}
          name="Cubagem M³"
          placeholder=""
          width="100%"
          type="text"
        />
        <InputPrimeReact
          disabled
          label="Peso cubado"
          value={cubedWeight}
          type="number"
          width="100px"
          suffix=" kg"
          className={"cubage"}
        />
      </div>
    </div>
  );
};

export default CubageRow;
